import { images } from "./importImg";

export function setWhiteLogo() {
  const currentSite = window.location.hostname;
  let logo;

  if (currentSite === "pdr.in.ua") {
    logo = images["pdrinua.svg"];
  } else if (currentSite === "pdr.ua") {
    logo = images["pdrua.svg"];
  } else {
    logo = images["pdrinua.svg"];
  }

  return logo;
}
