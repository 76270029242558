import { NavLink, useHistory } from "react-router-dom";
import { useEffect, useState } from "react";

import ProgressBarCar from "../ProgressBarCar/ProgressBarCar";
import Button from "../../common/Button/Button";
import ModalPay from "../../hoc/modalPay";
import ModalInfo from "../../common/Modal/ModalInfo";
import HeaderProfile from "./HeaderProfile";
import HeaderMobile from "./HeaderMobile";
import HeaderNav from "./HeaderNav";
import Burger from "./Burger";
import { images } from "../../helpers/importImg";

const Header = ({
  token,
  user,
  totalProgress,
  onGetTotalProgress,
  setLogin,
  onLogout,
  onGetUser,
  setUser,
}) => {
  // ---------------- SET HISTORY ------------------------
  const history = useHistory();

  // ---------------- SET LOCAL STATE ------------------------
  const [modalPay, setModalPay] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);

  const tokenData = sessionStorage.getItem("token");
  const currentSite = window.location.hostname;
  let logo;

  if (currentSite === "pdr.in.ua") {
    logo = images["pdrinua.png"];
  } else if (currentSite === "pdr.ua") {
    logo = images["pdrua.png"];
  } else {
    logo = images["pdrinua.png"];
  }

  useEffect(() => {
    if (tokenData) {
      setLogin(tokenData);
    }
    // eslint-disable-next-line
  }, []);

  // ---------------- SET TOTAL PROGRESS ------------------------
  useEffect(() => {
    if (token) {
      onGetTotalProgress(token);
    }
    // eslint-disable-next-line
  }, [token]);

  // ---------------- GET USER ------------------------
  useEffect(() => {
    if (token) {
      onGetUser(token);
    }
    // eslint-disable-next-line
  }, [token]);
  // ---------------- SET PREMIUM MODAL ------------------------
  const openModalPay = () => {
    if (token) {
      setModalPay(true);
      document.body.classList.add("open");
    } else {
      setModalInfo(true);
      document.body.classList.add("open");
    }

    if (openMenu || modalPay) {
      setOpenMenu(false);
      document.body.classList.add("open");
    }
  };

  // ---------------- SET MODAL MENU ------------------------
  const openMenuModal = (e) => {
    setOpenMenu(true);

    if (openMenu) {
      setOpenMenu(false);
    }
  };

  // -------------- LOGOUT ----------------
  const logoutUser = async () => {
    if (token) {
      onLogout(token);
      setLogin(null);
      setUser({});
      sessionStorage.removeItem("token");
      sessionStorage.clear();
      history.push("/");
      setOpenMenu(false);
    }
  };

  return (
    <>
      {modalPay && <ModalPay setModalPay={setModalPay} />}
      {modalInfo && <ModalInfo setModalInfo={setModalInfo} />}

      <header className={`header-learn ${openMenu ? "open" : ""}`}>
        <div className="header-learn-overlay"></div>
        <nav className="container header-learn__container">
          <NavLink to="/">
            <img
              src={logo}
              className="header-learn__logo"
              alt="Pdr logo"
              width="1"
              height="1"
            />
          </NavLink>

          <HeaderNav user={user} token={token} />

          <HeaderMobile
            user={user}
            token={token}
            totalProgress={totalProgress}
            openModalPay={openModalPay}
          />

          <HeaderProfile user={user} logoutUser={logoutUser} />

          {token
            ? totalProgress &&
              history.location.pathname !== "/finish" && (
                <div className="header-learn__progressbar">
                  <ProgressBarCar totalProgress={totalProgress} />
                </div>
              )
            : ""}

          {!user?.premium_check || !token ? (
            <Button
              btnText="Преміум"
              otherClass="header-learn__btn header-learn__btn--desktop"
              bg="yellow"
              rounded="md"
              size="sm"
              onClick={openModalPay}
            />
          ) : (
            ""
          )}

          <button className="header-learn__burger" onClick={openMenuModal}>
            <Burger />
          </button>
        </nav>
      </header>
    </>
  );
};

export default Header;
